@tailwind base;
@tailwind components;
@tailwind utilities;

 /* index.css
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Omnes Regular";   /*Can be any text*/
  src: local("Omnes"),
    url("./fonts/omnes-2-cdnfonts/Omnes Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Omnes Medium";   /*Can be any text*/
  src: local("Omnes"),
    url("./fonts/omnes-2-cdnfonts/Omnes Medium.ttf") format("truetype");
}
@font-face { 
  font-family: "SF Pro Display"; 
  font-style: normal; 
  font-weight: 100; 
  src: url("./fonts/SF-Pro-Text/SF-Pro.ttf") format("opentype")
}
@font-face {
  font-family: 'SF Pro Display Light';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/SF-Pro-Text/SF-Pro-Display-Light.otf") format("opentype")
}
 @font-face {
  font-family: 'SF Pro Display Medium';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/SF-Pro-Text/SF-Pro-Display-Medium.otf") format("opentype")
}
@font-face {
  font-family: 'SF Pro Display Semibold';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/SF-Pro-Text/SF-Pro-Text-Semibold.otf") format("opentype")
}

