.labelImage{
    position: absolute;
    width: 334px;
    height: 334px;
    left: calc(50% - 334px/2 - 0.5px);
    /* top: 113px; */
    /* top: 25px; */
    top:3.07%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* border-radius: 15px 15px 0px 0px; */
}
.labelDescription{
    position:absolute;
    /* top: 358px; */
    top:44%;
    width: 334px;
    height: 98px;
    left: calc(50% - 334px/2 - 0.5px);
    background: #FFFFFF;
    box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 12px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.noImageLabelDescription{
    position:absolute;
    /* top: 358px; */
    top:44%;
    width: 334px;
    height: 98px;
    left: calc(50% - 334px/2 - 0.5px);
    /* background: #FFFFFF;
    box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.1); */
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.noLabelWithImageDescription{
    position:absolute;
    /* top: 358px; */
    top:44%;
    width: 334px;
    height: 98px;
    left: calc(50% - 334px/2 - 0.5px);
    background: #FFFFFF;
    box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 12px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelFont{
    /* text-transform:capitalize; */
    width: 286px;
    height: 29px;
    left: calc(50% - 286px/2 - 0.5px);
    top: 482px;
    font-family: Omnes Medium;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height; or 29px */
    text-align: center;
    color: #000000;
}

.labelTimilineText{
    position: absolute;
    font-family:'SF Pro Display Light';
    font-style:normal;
    height: 0px;
    /* left: 66px; */
    left:20%;
    /* top: 540px; */
    /* top: 486px; */
    top: 60%;
    font-size: 17px;
    line-height: 0%;
    color: #7E7E83;
    text-transform: lowercase;
}
.labelTimilineText:first-letter{
    text-transform: capitalize;
}

.timelineBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 8px;
    position: absolute;                  
    width: 120px;
    height: 45px;
    /* left: 58px; */
    /* left:9px; */
   /* // top: 607px;                   */
   top:0px;
    border-radius: 10px;  
}

.svgPreparedOnDiv{
    position: absolute;
    width: 165px;
    height: 43px;
    left: calc(50% - 165px/2 - 85px);
    /* top: 557px; */
    /* top: 503px; */
    top:62%;
    background: #FFFFFF;
    opacity: 0.85;
    border-radius: 12px;
}
.svgDiv{
    position: absolute;
    width: 165px;
    height: 43px;
    left: calc(50% - 165px/2 + 84px);
    top: 557px;
    background-color: #FFFFFF;
    opacity: 0.85;
    border-radius: 12px;
}
.preparedOn{
    height: 22px;
    font-family: SF Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    display: flex;
    align-items: center;
    letter-spacing: -0.4px
    
}
.timelineText{
    height: 22px;
    font-family: 'SF Pro Display Medium';
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    flex: none;
    order:2;
    flex-grow: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
}

.emptyBox{
    box-sizing: border-box;
    position: absolute;
    width: 334px;
    height: 334px;
    left: calc(50% - 334px/2 - 0.5px);
    top: 195px;
    border: 3px dashed #D9D9D9;
    border-radius: 15px;
}
.emptyBox p{
    position: absolute;
    width: 286px;
    height: 25px;
    left: calc(50% - 286px/2 - 0.5px);
    top: 50%;
    font-family: 'Omnes Medium';
    font-style: normal;
    font-size: 21px;
    line-height: 120%;
    /* or 25px */
    text-align: center;
    letter-spacing: 0.04em;

    color: #9E9EA0;
}
.box1{
    font-family: 'Omnes Regular';
    font-style: normal;
    font-size: 16px;
    line-height: 0;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .preloadBox{
    position: relative;
    width: 375px;
    height: 812px;

    background: linear-gradient(359.87deg, #E6DDDB -0.72%, #F9F5ED 102.67%);
    backdrop-filter: blur(2px);
  }
  .preLoadImg{
    position: absolute;
    width: 118px;
    height: 118px;
    left: calc(50% - 118px/2 - 0.5px);
    /* top: 303px; */
    top:29.3%;
  }
  .errorBox{
    position: absolute;
    width: 286px;
    height: 25px;
    left: calc(50% - 286px/2 - 0.5px);
    top: 157px;
  }
  .errorBox p{
    font-family: 'Omnes';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 120%;
    /* or 25px */
    text-align: center;
    letter-spacing: 0.04em;
    color: #3D3B46;

  }
  .errorBox img{
    top: 157px;
  }
  .contactText{
    position: absolute;
    width: 286px;
    height: 25px;
    left: calc(50% - 286px/2 - 0.5px);


  }